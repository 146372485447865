import { userState } from '@atoms/user.atom'
import Router from '@components/router/router'
import { hasNoSubscription, isTrialActive } from '@components/utils/helper'
import React, { createContext, useEffect, useState } from 'react'
import { Slide, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useRecoilValue } from 'recoil'

export const BannerContext = createContext<{ bannerDisplay: boolean; setBannerDisplay: (state: boolean) => void } | null>(null)

const App: React.FC = () => {
  const user = useRecoilValue(userState)
  const [bannerDisplay, setBannerDisplay] = useState<boolean>(isTrialActive(user) || hasNoSubscription(user))

  useEffect(() => {
    setBannerDisplay(isTrialActive(user) || hasNoSubscription(user))
  }, [user])

  return (
    <>
      <BannerContext.Provider value={{ bannerDisplay, setBannerDisplay }}>
        <Router />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick={true}
          rtl={false}
          pauseOnFocusLoss={true}
          draggable={true}
          pauseOnHover={true}
          transition={Slide}
          theme="colored"
        />
      </BannerContext.Provider>
    </>
  )
}

export default App
